
// @ts-nocheck


export const localeCodes =  [
  "en",
  "fr",
  "bg",
  "hr",
  "cs",
  "da",
  "nl",
  "et",
  "fi",
  "de",
  "el",
  "hu",
  "ga",
  "it",
  "lv",
  "lt",
  "mt",
  "pl",
  "pt",
  "ro",
  "sk",
  "sl",
  "es",
  "sv"
]

export const localeLoaders = {
  "en": [],
  "fr": [],
  "bg": [],
  "hr": [],
  "cs": [],
  "da": [],
  "nl": [],
  "et": [],
  "fi": [],
  "de": [],
  "el": [],
  "hu": [],
  "ga": [],
  "it": [],
  "lv": [],
  "lt": [],
  "mt": [],
  "pl": [],
  "pt": [],
  "ro": [],
  "sk": [],
  "sl": [],
  "es": [],
  "sv": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    "en",
    "fr",
    "bg",
    "hr",
    "cs",
    "da",
    "nl",
    "et",
    "fi",
    "de",
    "el",
    "hu",
    "ga",
    "it",
    "lv",
    "lt",
    "mt",
    "pl",
    "pt",
    "ro",
    "sk",
    "sl",
    "es",
    "sv"
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": false
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "iso": "en",
    "code": "en"
  },
  {
    "iso": "fr",
    "code": "fr"
  },
  {
    "iso": "bg",
    "code": "bg"
  },
  {
    "iso": "hr",
    "code": "hr"
  },
  {
    "iso": "cs",
    "code": "cs"
  },
  {
    "iso": "da",
    "code": "da"
  },
  {
    "iso": "nl",
    "code": "nl"
  },
  {
    "iso": "et",
    "code": "et"
  },
  {
    "iso": "fi",
    "code": "fi"
  },
  {
    "iso": "de",
    "code": "de"
  },
  {
    "iso": "el",
    "code": "el"
  },
  {
    "iso": "hu",
    "code": "hu"
  },
  {
    "iso": "ga",
    "code": "ga"
  },
  {
    "iso": "it",
    "code": "it"
  },
  {
    "iso": "lv",
    "code": "lv"
  },
  {
    "iso": "lt",
    "code": "lt"
  },
  {
    "iso": "mt",
    "code": "mt"
  },
  {
    "iso": "pl",
    "code": "pl"
  },
  {
    "iso": "pt",
    "code": "pt"
  },
  {
    "iso": "ro",
    "code": "ro"
  },
  {
    "iso": "sk",
    "code": "sk"
  },
  {
    "iso": "sl",
    "code": "sl"
  },
  {
    "iso": "es",
    "code": "es"
  },
  {
    "iso": "sv",
    "code": "sv"
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
