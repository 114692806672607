import {
  faSpinner as freeFasFaSpinner,
  faCheck as freeFasFaCheck,
  faPlus as freeFasFaPlus,
  faXmark as freeFasFaXmark,
  faCloudUpload as freeFasFaCloudUpload,
  faInfoCircle as freeFasFaInfoCircle,
  faExclamationCircle as freeFasFaExclamationCircle,
  faFilePdf as freeFasFaFilePdf,
  faTrash as freeFasFaTrash
} from '@fortawesome/free-solid-svg-icons'

export default {freeFasFaSpinner, freeFasFaCheck, freeFasFaPlus, freeFasFaXmark, freeFasFaCloudUpload, freeFasFaInfoCircle, freeFasFaExclamationCircle, freeFasFaFilePdf, freeFasFaTrash}