import payload_plugin_e0k4tW9bdI from "/Users/jonathancarr/pinotqr/app/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.mjs";
import revive_payload_client_4sVQNw7RlN from "/Users/jonathancarr/pinotqr/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/jonathancarr/pinotqr/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/jonathancarr/pinotqr/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/jonathancarr/pinotqr/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/jonathancarr/pinotqr/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/jonathancarr/pinotqr/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/jonathancarr/pinotqr/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/Users/jonathancarr/pinotqr/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/jonathancarr/pinotqr/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/jonathancarr/pinotqr/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import fontawesome_a3gDgrBaWH from "/Users/jonathancarr/pinotqr/app/node_modules/@vesp/nuxt-fontawesome/dist/runtime/plugins/fontawesome.mjs";
import plugin_client_LcKgStRyi6 from "/Users/jonathancarr/pinotqr/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_5drvKYmdqw from "/Users/jonathancarr/pinotqr/app/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.mjs";
import plugin_client_W5VNcGB8FU from "/Users/jonathancarr/pinotqr/app/node_modules/nuxt-3-intercom/dist/runtime/plugin.client.mjs";
import i18n_yfWm7jX06p from "/Users/jonathancarr/pinotqr/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_mik7N71GmK from "/Users/jonathancarr/pinotqr/app/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import plugin_client_mLgZJrmjnn from "/Users/jonathancarr/pinotqr/app/node_modules/nuxt-vuefire/dist/runtime/auth/plugin.client.mjs";
import templates_plugin_2472853b_Sza8IZcEtx from "/Users/jonathancarr/pinotqr/app/.nuxt/templates.plugin.2472853b.ejs";
import notification_6uqh0w74Kl from "/Users/jonathancarr/pinotqr/app/plugins/notification.js";
import sentry_PZVGm6LH3G from "/Users/jonathancarr/pinotqr/app/plugins/sentry.js";
import vue_query_wzYBgYXrkI from "/Users/jonathancarr/pinotqr/app/plugins/vue-query.js";
export default [
  payload_plugin_e0k4tW9bdI,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  fontawesome_a3gDgrBaWH,
  plugin_client_LcKgStRyi6,
  plugin_5drvKYmdqw,
  plugin_client_W5VNcGB8FU,
  i18n_yfWm7jX06p,
  plugin_client_mik7N71GmK,
  plugin_client_mLgZJrmjnn,
  templates_plugin_2472853b_Sza8IZcEtx,
  notification_6uqh0w74Kl,
  sentry_PZVGm6LH3G,
  vue_query_wzYBgYXrkI
]