import {
  VueFire,
  useSSRInitialState,
} from 'vuefire'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  const firebaseApp = nuxtApp.$firebaseApp

  nuxtApp.vueApp.use(VueFire, { firebaseApp })

  
})
