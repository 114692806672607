import type { ModuleOptions } from '#cookie-control/types'

export default {
  "barPosition": "bottom-full",
  "closeModalOnClickOutside": true,
  "colors": {
    "barBackground": "#000",
    "barButtonBackground": "#fff",
    "barButtonColor": "#000",
    "barButtonHoverBackground": "#333",
    "barButtonHoverColor": "#fff",
    "barTextColor": "#fff",
    "checkboxActiveBackground": "#00A34A",
    "checkboxActiveCircleBackground": "#fff",
    "checkboxDisabledBackground": "#ddd",
    "checkboxDisabledCircleBackground": "#fff",
    "checkboxInactiveBackground": "#000",
    "checkboxInactiveCircleBackground": "#fff",
    "controlButtonBackground": "#fff",
    "controlButtonHoverBackground": "#000",
    "controlButtonIconColor": "#000",
    "controlButtonIconHoverColor": "#fff",
    "focusRingColor": "#808080",
    "modalBackground": "#fff",
    "modalButtonBackground": "#000",
    "modalButtonColor": "#fff",
    "modalButtonHoverBackground": "#333",
    "modalButtonHoverColor": "#fff",
    "modalOverlay": "#000",
    "modalOverlayOpacity": 0.8,
    "modalTextColor": "#000",
    "modalUnsavedColor": "#fff"
  },
  "cookies": {
    "necessary": [
      {
        "description": {
          "en": "Login cookies are crucial for secure access to your account. They verify your identity and maintain session continuity. By using our site, you agree to their use. Check our privacy policy for more details.",
          "fr": "Login cookies are crucial for secure access to your account. They verify your identity and maintain session continuity. By using our site, you agree to their use. Check our privacy policy for more details.",
          "bg": "Login cookies are crucial for secure access to your account. They verify your identity and maintain session continuity. By using our site, you agree to their use. Check our privacy policy for more details.",
          "hr": "Login cookies are crucial for secure access to your account. They verify your identity and maintain session continuity. By using our site, you agree to their use. Check our privacy policy for more details.",
          "cs": "Login cookies are crucial for secure access to your account. They verify your identity and maintain session continuity. By using our site, you agree to their use. Check our privacy policy for more details.",
          "da": "Login cookies are crucial for secure access to your account. They verify your identity and maintain session continuity. By using our site, you agree to their use. Check our privacy policy for more details.",
          "nl": "Login cookies are crucial for secure access to your account. They verify your identity and maintain session continuity. By using our site, you agree to their use. Check our privacy policy for more details.",
          "fi": "Login cookies are crucial for secure access to your account. They verify your identity and maintain session continuity. By using our site, you agree to their use. Check our privacy policy for more details.",
          "de": "Login cookies are crucial for secure access to your account. They verify your identity and maintain session continuity. By using our site, you agree to their use. Check our privacy policy for more details.",
          "hu": "Login cookies are crucial for secure access to your account. They verify your identity and maintain session continuity. By using our site, you agree to their use. Check our privacy policy for more details.",
          "it": "Login cookies are crucial for secure access to your account. They verify your identity and maintain session continuity. By using our site, you agree to their use. Check our privacy policy for more details.",
          "lt": "Login cookies are crucial for secure access to your account. They verify your identity and maintain session continuity. By using our site, you agree to their use. Check our privacy policy for more details.",
          "pl": "Login cookies are crucial for secure access to your account. They verify your identity and maintain session continuity. By using our site, you agree to their use. Check our privacy policy for more details.",
          "pt": "Login cookies are crucial for secure access to your account. They verify your identity and maintain session continuity. By using our site, you agree to their use. Check our privacy policy for more details.",
          "ro": "Login cookies are crucial for secure access to your account. They verify your identity and maintain session continuity. By using our site, you agree to their use. Check our privacy policy for more details.",
          "sk": "Login cookies are crucial for secure access to your account. They verify your identity and maintain session continuity. By using our site, you agree to their use. Check our privacy policy for more details.",
          "es": "Login cookies are crucial for secure access to your account. They verify your identity and maintain session continuity. By using our site, you agree to their use. Check our privacy policy for more details.",
          "sv": "Login cookies are crucial for secure access to your account. They verify your identity and maintain session continuity. By using our site, you agree to their use. Check our privacy policy for more details."
        },
        "name": {
          "en": "Login Cookie",
          "fr": "Login Cookie",
          "bg": "Login Cookie",
          "hr": "Login Cookie",
          "cs": "Login Cookie",
          "da": "Login Cookie",
          "nl": "Login Cookie",
          "fi": "Login Cookie",
          "de": "Login Cookie",
          "hu": "Login Cookie",
          "it": "Login Cookie",
          "lt": "Login Cookie",
          "pl": "Login Cookie",
          "pt": "Login Cookie",
          "ro": "Login Cookie",
          "sk": "Login Cookie",
          "es": "Login Cookie",
          "sv": "Login Cookie"
        }
      }
    ],
    "optional": [
      {
        "id": "intercom",
        "name": "Intercom",
        "links": {
          "https://www.intercom.com/legal/privacy": "Privacy Policy"
        }
      },
      {
        "name": "functional"
      }
    ]
  },
  "cookieExpiryOffsetMs": 31536000000,
  "cookieNameIsConsentGiven": "ncc_c",
  "cookieNameCookiesEnabledIds": "ncc_e",
  "cookieOptions": {
    "path": "/"
  },
  "isAcceptNecessaryButtonEnabled": false,
  "isControlButtonEnabled": false,
  "isCookieIdVisible": false,
  "isCssEnabled": true,
  "isCssPonyfillEnabled": false,
  "isDashInDescriptionEnabled": true,
  "isIframeBlocked": true,
  "isModalForced": false,
  "locales": [
    "en",
    "fr",
    "bg",
    "hr",
    "cs",
    "da",
    "nl",
    "fi",
    "de",
    "hu",
    "it",
    "lt",
    "pl",
    "pt",
    "ro",
    "sk",
    "es",
    "sv",
    "en"
  ],
  "localeTexts": {
    "en": {
      "accept": "Accept",
      "acceptAll": "Accept all",
      "bannerDescription": "We use our own cookies and third-party cookies so that we can display this website correctly and better understand how this website is used, with a view to improving the services we offer. A decision on cookie usage permissions can be changed anytime using the cookie button that will appear after a selection has been made on this banner.",
      "bannerTitle": "Cookies",
      "close": "Close",
      "cookiesFunctional": "Functional cookies",
      "cookiesNecessary": "Necessary cookies",
      "cookiesOptional": "Optional cookies",
      "decline": "Decline",
      "declineAll": "Decline all",
      "here": "here",
      "iframeBlocked": "To see this, please enable functional cookies",
      "manageCookies": "Learn more and customize",
      "save": "Save",
      "settingsUnsaved": "You have unsaved settings"
    },
    "fr": {
      "accept": "Accepter",
      "acceptAll": "Tout accepter",
      "bannerDescription": "Nous utilisons des cookies d’origine et des cookies tiers. Ces cookies sont destinés à vous offrir une navigation optimisée sur ce site web et de nous donner un aperçu de son utilisation, en vue de l’amélioration des services que nous offrons. En poursuivant votre navigation, nous considérons que vous acceptez l’usage des cookies.",
      "bannerTitle": "Cookies",
      "close": "Fermer",
      "cookiesFunctional": "Cookies fonctionnels",
      "cookiesNecessary": "Les cookies obligatoires",
      "cookiesOptional": "Les cookies optionnels",
      "decline": "J'accepte le nécessaire",
      "declineAll": "Tout refuser",
      "here": "ici",
      "iframeBlocked": "Pour voir cela, veuillez activer les cookies fonctionnels",
      "manageCookies": "Gérer les cookies",
      "save": "Sauvegarder",
      "settingsUnsaved": "Vous avez des paramètres non sauvegardés"
    },
    "bg": {
      "accept": "Приеми",
      "acceptAll": "Приеми всички",
      "bannerDescription": "Ние използваме наши \"бисквитки\" и \"бисквитки\" от трети страни, за да можем да показваме правилно този уеб сайт и да разберем по-добре как се използва, с цел подобряване на предлаганите от нас услуги. Разрешението за използване на \"бисквитки\" може да бъде променено по всяко време, като се използва бутонът за бисквитки, който ще се появи след като бъде направен избор на този банер.",
      "bannerTitle": "\"Бисквитки\"",
      "close": "Затвори",
      "cookiesFunctional": "Функционални \"бисквитки\"",
      "cookiesNecessary": "Нужни \"бисквитки\"",
      "cookiesOptional": "Незадължителни \"бисквитки\"",
      "decline": "Откажи",
      "declineAll": "Откажи всички",
      "here": "тук",
      "iframeBlocked": "За да видите това, моля, позволете функционалните \"бисквитки\"",
      "manageCookies": "Вижте повече и настройте",
      "save": "Запази",
      "settingsUnsaved": "Имате незапазени настройки"
    },
    "hr": {
      "accept": "Dozvoli",
      "acceptAll": "Dozvoli sve",
      "bannerDescription": "Koristimo vlastite kolačiće i kolačiće treće strane kako bismo Vam mogli prikazati web stranicu i razumijeti kako je koristite, s pogledom na poboljšanje usluga koje nudimo. Ako nastavite s pregledavanjem, smatramo da prihvaćate upotrebu kolačića.",
      "bannerTitle": "Kolačići",
      "close": "Zatvori",
      "cookiesFunctional": "Funkcionalni kolačići",
      "cookiesNecessary": "Obavezni kolačići",
      "cookiesOptional": "Neobavezni kolačići",
      "decline": "Prihvaćam obavezne",
      "declineAll": "Obriši sve",
      "here": "ovdje",
      "iframeBlocked": "Da bi vidjeli ovo, molimo Vas omogućite funkcionalne kolačiće",
      "manageCookies": "Upravljaj kolačićima",
      "save": "Spremi",
      "settingsUnsaved": "Imate nespremljenih postavki"
    },
    "cs": {
      "accept": "Přijmout",
      "acceptAll": "Přijmout vše",
      "bannerDescription": "Používáme soubory cookie a cookie třetích stran, abychom mohli vše správně zobrazovat a lépe porozumět tomu, jak tento web používáte, s cílem zlepšit nabízené služby. Rozhodnutí lze kdykoli změnit pomocí tlačítka cookie, které se zobrazí po provedení výběru na tomto banneru.",
      "bannerTitle": "Cookies",
      "close": "Zavřít",
      "cookiesFunctional": "Obslužné cookies",
      "cookiesNecessary": "Nezbytné cookies",
      "cookiesOptional": "Volitelné cookies",
      "decline": "Zamítnout",
      "declineAll": "Zamítnout vše",
      "here": "zde",
      "iframeBlocked": "Chcete-li ji zobrazit, povolte obslužné cookies",
      "manageCookies": "Další informace a přizpůsobení",
      "save": "Uložit",
      "settingsUnsaved": "Máte neuložená nastavení"
    },
    "da": {
      "accept": "Accepter",
      "acceptAll": "Accepter alle",
      "bannerDescription": "Vi bruger vores egne cookies og tredjepartscookies, så vi kan vise dig denne hjemmeside og bedre forstå hvordan du bruger den, med henblik på at forbedre de tjenester som vi tilbyder. Du kan til enhver tid trække dit samtykke vedr. brug af cookies tilbage. Dette gøres ved hjælp af cookie-knappen, der vises når et valg er foretaget på dette banner.",
      "bannerTitle": "Cookies",
      "close": "Luk",
      "cookiesFunctional": "Funktionelle cookies",
      "cookiesNecessary": "Nødvendige cookies",
      "cookiesOptional": "Valgfrie cookies",
      "decline": "Afvis",
      "declineAll": "Afvis alle",
      "here": "Her",
      "iframeBlocked": "For at se dette skal du aktivere funktionelle cookies",
      "manageCookies": "Læs mere og tilpas",
      "save": "Gem",
      "settingsUnsaved": "Du har indstillinger, der ikke er gemt"
    },
    "nl": {
      "accept": "Aanvaarden",
      "acceptAll": "Accepteer alles",
      "bannerDescription": "We gebruiken onze eigen cookies en third-party cookies om onze site te tonen en om te leren hoe deze gebruikt wordt, met het oog om de services die we verlenen te verbeteren. Door verder te gaan op onze site gaan we ervanuit dat hiermee akkoord gegaan wordt.",
      "bannerTitle": "Cookies",
      "close": "Sluiten",
      "cookiesFunctional": "Functionele cookies",
      "cookiesNecessary": "Noodzakelijke cookies",
      "cookiesOptional": "Optionele cookies",
      "decline": "Accepteer noodzakelijk",
      "declineAll": "Verwijder alles",
      "here": "hier",
      "iframeBlocked": "Om dit te kunnen bekijken dienen functionele cookies geaccepteerd te worden",
      "manageCookies": "Beheer cookies",
      "save": "Opslaan",
      "settingsUnsaved": "Er zijn niet-opgeslagen instellingen"
    },
    "fi": {
      "accept": "Hyväksy",
      "acceptAll": "Hyväksy kaikki",
      "bannerDescription": "Käytämme omia ja kolmannen osapuolen evästeitä verkkosivuston näyttämiseen ja sen käytön ymmärtämiseen, jotta voimme parantaa palveluitamme. Evästeiden käyttöoikeuksien valinta voidaan muuttaa koska tahansa evästeiden valintanapin avulla, joka ilmestyy valinnan jälkeen tähän banneriin.",
      "bannerTitle": "Evästeet",
      "close": "Sulje",
      "cookiesFunctional": "Toiminnalliset evästeet",
      "cookiesNecessary": "Pakolliset evästeet",
      "cookiesOptional": "Valinnaiset evästeet",
      "decline": "Kiellä",
      "declineAll": "Kiellä kaikki",
      "here": "täältä",
      "iframeBlocked": "Nähdäksesi tämän, ole hyvä ja hyväksy toiminnalliset evästeet.",
      "manageCookies": "Lue lisää ja hallitse evästeitä",
      "save": "Tallenna",
      "settingsUnsaved": "Sinulla on tallentamattomia muutoksia."
    },
    "de": {
      "accept": "Akzeptieren",
      "acceptAll": "Alle akzeptieren",
      "bannerDescription": "Wir verwenden eigene Cookies und Cookies von Drittanbietern, damit wir diese Webseite korrekt darstellen können und besser verstehen, wie diese Webseite genutzt wird, um unser Angebot zu verbessern. Eine Entscheidung über die Erlaubnis zur Verwendung von Cookies kann jederzeit über den Cookie-Button geändert werden, der erscheint, nachdem eine Auswahl auf diesem Banner getroffen wurde.",
      "bannerTitle": "Cookies",
      "close": "Schließen",
      "cookiesFunctional": "Funktionale Cookies",
      "cookiesNecessary": "Notwendige Cookies",
      "cookiesOptional": "Optionale Cookies",
      "decline": "Ablehnen",
      "declineAll": "Alle ablehnen",
      "here": "hier",
      "iframeBlocked": "Um den Inhalt zu sehen, aktivieren Sie bitte funktionale Cookies",
      "manageCookies": "Mehr erfahren und anpassen",
      "save": "Speichern",
      "settingsUnsaved": "Es gibt nicht gespeicherte Einstellungen"
    },
    "hu": {
      "accept": "Elfogad",
      "acceptAll": "Összes elfogadása",
      "bannerDescription": "Saját, illetve harmadik féltől származó sütiket használunk annak érdekében, hogy megmutassuk ezt a weboldalt, és jobban megértsük, hogyan használja azt, azzal a céllal, hogy javítsuk az általunk kínált szolgáltatásokat. Ha folytatod a böngészést, úgy gondoljuk, hogy elfogadtad a sütiket.",
      "bannerTitle": "Sütik",
      "close": "Bezár",
      "cookiesFunctional": "Funkcionális sütik",
      "cookiesNecessary": "Szükséges sütik",
      "cookiesOptional": "Opcionális sütik",
      "decline": "Elfogadom a szükségeset",
      "declineAll": "Összes elutasítása",
      "here": "itt",
      "iframeBlocked": "Ennek megtekintéséhez, engedélyezd a funkcionális sütik használatát",
      "manageCookies": "Sütik kezelése",
      "save": "Mentés",
      "settingsUnsaved": "Mentés nélküli beállítások vannak"
    },
    "it": {
      "accept": "Accetta",
      "acceptAll": "Accetta tutto",
      "bannerDescription": "Utilizziamo cookie propri e di terzi per mostrarvi la pagina web e capire come la utilizzate, nonché per migliorare i servizi che offriamo. Se continuate a navigare, consideriamo che accettate il loro utilizzo.",
      "bannerTitle": "Cookies",
      "close": "Chiudi",
      "cookiesFunctional": "Cookie funzionali",
      "cookiesNecessary": "Cookie necessari",
      "cookiesOptional": "Cookie opzionali",
      "decline": "Accetto il necessario",
      "declineAll": "Cancella tutto",
      "here": "qui",
      "iframeBlocked": "Per vedere questo, si prega di abilitare i cookie funzionali",
      "manageCookies": "Gestisci i cookie",
      "save": "Salva",
      "settingsUnsaved": "Ci sono impostazioni non salvate"
    },
    "lt": {
      "accept": "Sutinku",
      "acceptAll": "Sutinku su visais",
      "bannerDescription": "Mes naudojame savo slapukus and trečiųjų šalių slapukus tam, kad galėtume geriau suprasti kaip svetainė veikia ir yra naudojama bei tobulinti savo teikiamas paslaugas. Jūs bet kuriuo metu galite pakeisti sutikimą dėl slapukų naudojimo paspaudę slapukų mygtuką kuris atsiras kai šioje reklamjuostėje padarysite pasirinkimą.",
      "bannerTitle": "Slapukai",
      "close": "Uždaryti",
      "cookiesFunctional": "Funkciniai slapukai",
      "cookiesNecessary": "Būtinieji slapukai",
      "cookiesOptional": "Pasirinktiniai slapukai",
      "decline": "Atšaukti",
      "declineAll": "Atšaukti viską",
      "here": "čia",
      "iframeBlocked": "Norėdami matyti, leiskite naudoti funkcinius slapukus",
      "manageCookies": "Sužinoti daugiau ir pritaikyti",
      "save": "Išsaugoti",
      "settingsUnsaved": "Jūs neišsaugojote visų nustatymų"
    },
    "pl": {
      "accept": "Akceptuj",
      "acceptAll": "Akceptuj wszystkie",
      "bannerDescription": "Używamy własnych plików cookie i plików cookie stron trzecich, abyśmy mogli poprawnie wyświetlać tę witrynę i lepiej zrozumieć, w jaki sposób ta witryna jest używana, w celu ulepszenia oferowanych przez nas usług. Decyzję w sprawie zgody na korzystanie z plików cookie można zmienić w dowolnym momencie za pomocą przycisku cookie, który pojawi się po dokonaniu wyboru na tym banerze.",
      "bannerTitle": "Polityka cookies",
      "close": "Zamknij",
      "cookiesFunctional": "Funkcjonalne pliki cookies",
      "cookiesNecessary": "Niezbędne pliki cookies",
      "cookiesOptional": "Opcjonalne pliki cookies",
      "decline": "Zrezygnuj",
      "declineAll": "Zrezygnuj ze wszystkich",
      "here": "tutaj",
      "iframeBlocked": "W celu wyświetlenia wymagane jest włączenie funkcjonalnych plików cookies",
      "manageCookies": "Dowiedz się więcej",
      "save": "Zapisz",
      "settingsUnsaved": "Masz niezapisane ustawienia"
    },
    "pt": {
      "accept": "Aceitar todos",
      "acceptAll": "Aceitar",
      "bannerDescription": "Nós utilizamos os nossos próprios cookies e de terceiros para que possamos lhe mostrar este site e compreender a forma como o utiliza, de forma a melhorarmos os serviços que oferecemos. Ao continuar a navegar no site, consideramos que aceitou a utilização de cookies.",
      "bannerTitle": "Cookies",
      "close": "Fechar",
      "cookiesFunctional": "Cookies funcionais",
      "cookiesNecessary": "Cookies necessários",
      "cookiesOptional": "Cookies opcionais",
      "decline": "Eu aceito o necessário",
      "declineAll": "Apagar todos",
      "here": "aqui",
      "iframeBlocked": "Para visualizar isto, por favor, active os cookies funcionais",
      "manageCookies": "Gerir cookies",
      "save": "Gravar",
      "settingsUnsaved": "Tem alterações não guardadas"
    },
    "ro": {
      "accept": "Acceptă",
      "acceptAll": "Acceptă toate",
      "bannerDescription": "Utilizăm propriile noastre cookie-uri și cookie-uri ale terților pentru a afișa corect acest site web și pentru a înțelege mai bine modul în care este utilizat acest site web, cu scopul de a îmbunătăți serviciile pe care le oferim. Decizia cu privire la permisiunile de utilizare a cookie-urilor poate fi modificată în orice moment folosind butonul de cookie-uri care va apărea după ce ați făcut o selecție pe această banner.",
      "bannerTitle": "Cookie-uri",
      "close": "Închide",
      "cookiesFunctional": "Cookie-uri funcționale",
      "cookiesNecessary": "Cookie-uri necesare",
      "cookiesOptional": "Cookie-uri opționale",
      "decline": "Declină",
      "declineAll": "Declină toate",
      "here": "aici",
      "iframeBlocked": "Pentru a vedea aceasta, activează cookie-urile funcționale",
      "manageCookies": "Află mai multe și configurează",
      "save": "Salvează",
      "settingsUnsaved": "Ai setări nesalvate"
    },
    "sk": {
      "accept": "Prijať",
      "acceptAll": "Prijať všetko",
      "bannerDescription": "Súbory cookie a súbory cookie tretích strán používame na to, aby sme mohli všetko správne zobraziť a lepšie pochopiť, ako používate túto webovú lokalitu, aby sme mohli zlepšiť ponúkané služby. Rozhodnutia môžete kedykoľvek zmeniť pomocou tlačidla cookie, ktoré sa zobrazí po vykonaní výberu na tomto banneri.",
      "bannerTitle": "Cookies",
      "close": "Zatvoriť",
      "cookiesFunctional": "Obslužné cookies",
      "cookiesNecessary": "Nevyhnutné  cookies",
      "cookiesOptional": "Voliteľné cookies",
      "decline": "Odmietnuť",
      "declineAll": "Odmietnuť všetky",
      "here": "tu",
      "iframeBlocked": "Ak ho chcete zobraziť, povoľte obslužné cookies",
      "manageCookies": "Ďalšie informácie a prispôsobenie",
      "save": "Uložiť",
      "settingsUnsaved": "Máte neuložené nastavenia"
    },
    "es": {
      "accept": "Aceptar",
      "acceptAll": "Aceptar todo",
      "bannerDescription": "Utilizamos cookies propias y de terceros para poder mostrarle una página web y comprender cómo la utiliza, con el fin de mejorar los servicios que ofrecemos. Si continúa navegando, consideramos que acepta su uso.",
      "bannerTitle": "Cookies",
      "close": "Cerrar",
      "cookiesFunctional": "Cookies funcionales",
      "cookiesNecessary": "Cookies obligatorias",
      "cookiesOptional": "Cookies opcionales",
      "decline": "Acepto lo necesario",
      "declineAll": "Borrar todo",
      "here": "aquí",
      "iframeBlocked": "Para ver esto, por favor habilita las cookies funcionales.",
      "manageCookies": "Administrar cookies",
      "save": "Guardar",
      "settingsUnsaved": "Tienes configuraciones no guardadas"
    },
    "sv": {
      "accept": "Godkänn",
      "acceptAll": "Godkänn alla",
      "bannerDescription": "Vi använder egna och tredjeparts-cookies för att visa webbplatsen korrekt samt för att förstå hur webbplatsen används i syfte att kunna förbättra de tjänster vi erbjuder. Ditt beslut kring användandet av cookies kan närsomhelst ändras via cookie-knappen på webbplatsen.",
      "bannerTitle": "Cookies",
      "close": "Stäng",
      "cookiesFunctional": "Funktionella cookies",
      "cookiesNecessary": "Nödvändiga cookies",
      "cookiesOptional": "Frivilliga cookies",
      "decline": "Avslå",
      "declineAll": "Avslå alla",
      "here": "här",
      "iframeBlocked": "För att ta del av detta, var god godkänn funktionella cookies",
      "manageCookies": "Anpassa",
      "save": "Spara",
      "settingsUnsaved": "Du har inställningar som inte är sparade"
    }
  }
} as ModuleOptions